/**
 * Logo
 */
 .logotype {
     font-family: 'glacial_cl', sans-serif;
     text-transform: uppercase;
 }
.logo {
    display: block;
    padding: 0;
    color: #000;
    text-decoration: none;
}
.logo .logotype {
    font-size: 2rem;
    margin: 0 0 -1px 3px;
    display: inline-block;
    vertical-align: bottom;
}
.logo .logotype span {
    display: block;
    font-size: 0.75em;
    /* letter-spacing: 0.15em; */
    letter-spacing: 0.24em;
}
.logo .logotype span:first-child {
    font-size: 0.625em;
    letter-spacing: 0;
}

.logo .grid {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: bottom;
    font-size: 0;
}
.logo .row {
    display: block;
    margin: 0 0 5px 0;
}
.logo .row:last-child {
    margin-bottom: 0;
}
.logo .cell {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 0 3px 0 0;
}

.logo.gray .cell {
    background-color: #ccc;
}

.logo.animated .row:nth-child(1) .cell:nth-child(1) {
    animation: pulse 87s infinite;
    animation-delay: -1.5s;
}
.logo.animated .row:nth-child(1) .cell:nth-child(2) {
    animation: pulse 52s infinite;
    animation-delay: -0.5s;
}
.logo.animated .row:nth-child(1) .cell:nth-child(3) {
    animation: pulse 79s infinite;
    animation-delay: -6s;
}
.logo.animated .row:nth-child(1) .cell:nth-child(4) {
    animation: pulse 55s infinite;
    animation-delay: -10s;
}
.logo.animated .row:nth-child(2) .cell:nth-child(1) {
    animation: pulse 64s infinite;
    animation-delay: -7.2s;
}
.logo.animated .row:nth-child(2) .cell:nth-child(2) {
    animation: pulse 98s infinite;
    animation-delay: -25s;
}
.logo.animated .row:nth-child(2) .cell:nth-child(3) {
    animation: pulse 51s infinite;
    animation-delay: -35s;
}
.logo.animated .row:nth-child(2) .cell:nth-child(4) {
    animation: pulse 76s infinite;
    animation-delay: -20s;
}
.logo.animated .row:nth-child(3) .cell:nth-child(1) {
    animation: pulse 52s infinite;
    animation-delay: -3.5s;
}
.logo.animated .row:nth-child(3) .cell:nth-child(2) {
    animation: pulse 79s infinite;
    animation-delay: -8.5s;
}
.logo.animated .row:nth-child(3) .cell:nth-child(3) {
    animation: pulse 65s infinite;
    animation-delay: -4s;
}
.logo.animated .row:nth-child(3) .cell:nth-child(4) {
    animation: pulse 54s infinite;
    animation-delay: -17s;
}

@keyframes pulse {
    0%, 100% {
        background-color: #edc40b;
    }
    9% {
        background-color: #edc40b;
    }
    10% {
        background-color: #ff9100;
    }
    17% {
        background-color: #ff9100;
    }
    18% {
        background-color: #ee91bf;
    }
    25% {
        background-color: #ee91bf;
    }
    26% {
        background-color: #aa7fa7;
    }
    33% {
        background-color: #aa7fa7;
    }
    34% {
        background-color: #5ec232;
    }
    41% {
        background-color: #5ec232;
    }
    42% {
        background-color: #65b7ff;
    }
    49% {
        background-color: #65b7ff;
    }
    50% {
        background-color: #6f879c;
    }
    57% {
        background-color: #6f879c;
    }
    58% {
        background-color: #6dbb8b;
    }
    65% {
        background-color: #6dbb8b;
    }
    66% {
        background-color: #ee5f63;
    }
    73% {
        background-color: #ee5f63;
    }
    74% {
        background-color: #f0ee0c;
    }
    81% {
        background-color: #f0ee0c;
    }
    82% {
        background-color: #a1a3a9;
    }
    89% {
        background-color: #a1a3a9;
    }
    90% {
        background-color: #9c896d;
    }
    99% {
        background-color: #9c896d;
    }
}
